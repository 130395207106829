<template>
  <div>
      <MatchmakingContainer
      :marketplaces="marketplaces"
      :marketplaceProjects="marketplaceProjects"
      :marketplaceProjectOffers="marketplaceProjectOffers"
      :loadingProjects="loadingProjects"
      :page="page"
      :perPage="perPage"
      @isForbitUploadProjects="forbitUploadProjects"
      @changeMarketplace="changeMarketplace"
      @getMarketplaceProjects="getMarketplaceProjects"
      @toggleSavedProject="toggleSavedProject"
      />
  </div>
</template>

<script>
import MatchmakingContainer from '@/components//matchmaking/MatchmakingContainer.vue';
import MatchmakingService from '@/services/MatchmakingService';
import exception from '@/mixins/exception';
import pagination from '@/mixins/pagination';

export default {
  name: 'Matchmaking',
  components: { MatchmakingContainer },
  mixins: [exception, pagination],
  data() {
    return {
      marketplaces: [],
      marketplaceProjects: [],
      marketplaceProjectOffers: {},
      activeMarketplaceIndex: -100, // -100 is for all marketplaces
      page: 1,
      perPage: 5,
      isForbitUploadProjects: false,
      loadingProjects: true,
    };
  },
  methods: {
    changeMarketplace(id = -100) { // -100 is for all marketplaces
      this.activeMarketplaceIndex = id === -100 ? -100
        : this.marketplaces.findIndex((m) => m.id === id);
      this.marketplaceProjects = [];
      this.page = 1;
      this.isForbitUploadProjects = false;
      const marketplace = this.marketplaces.find((m) => m.id === id);
      this.getMarketplaceProjects(id === -100 ? -100 : marketplace.id);
    },
    async getMarketplaceProjects(marketplaceId = -100, isCreatedNewProject = false) {
      if (isCreatedNewProject || !this.isForbitUploadProjects) {
        const query = {
          page: !isCreatedNewProject ? this.page : 1,
          perPage: this.perPage,
        };
        try {
          this.$store.commit('SET_IS_LOADING', true);
          this.loadingProjects = true;
          const res = await MatchmakingService.marketplaceProjects(
            marketplaceId, this.isBuyer, query,
          );
          // if (res.data.length === 0) {
          // this.isForbitUploadProjects = true;
          // }
          this.marketplaceProjects = isCreatedNewProject ? res.data
            : [...this.marketplaceProjects, ...res.data];
          // this.page = +res.headers['x-pagination-current-page'] + 1;
        } catch (error) {
          this.handleException(error);
        } finally {
          this.$store.commit('SET_IS_LOADING', false);
          this.loadingProjects = false;
        }
      }
    },
    async getMarketplaces() {
      try {
        this.$store.commit('SET_IS_LOADING', true);
        const { data } = await MatchmakingService.marketplaces();
        this.marketplaces = data;
      } catch (error) {
        this.handleException(error);
      } finally {
        this.$store.commit('SET_IS_LOADING', false);
      }
    },
    async getAllMarketplaceProjects() {
      try {
        this.$store.commit('SET_IS_LOADING', true);
        const res = await MatchmakingService.marketplaceProjects();
        this.page = +res.headers['x-pagination-current-page'] + 1;
        this.marketplaceProjects = res.data;
      } catch (error) {
        this.handleException(error);
      } finally {
        this.$store.commit('SET_IS_LOADING', false);
      }
    },
    toggleSavedProject(projectId) {
      const projectIndex = this.marketplaceProjects.findIndex((p) => p.id === projectId);
      this.marketplaceProjects[projectIndex]
        .saved = !this.marketplaceProjects[projectIndex].saved;
    },
    isShowProjects() {
      return this.marketplaceProjects.length > 0;
    },
    forbitUploadProjects(predicate) {
      this.isForbitUploadProjects = predicate;
    },
    handleScroll() {
      const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
      const footer = document.getElementById('footer');
      const scrollBuffer = 100;
      if (window.innerWidth <= 768
        && scrollTop + clientHeight >= scrollHeight - footer.scrollHeight - scrollBuffer
        && !this.isForbitUploadProjects) {
        //     paginatedMarketplaceProjects() {
        //   return this.marketplaceProjects.slice(0, this.page * this.perPage);
        // },
        // this.getMarketplaceProjects(
        //   this.activeMarketplaceIndex === -100 ? -100
        //     : this.marketplaces[this.activeMarketplaceIndex]?.id,
        // );
        if (this.marketplaceProjects.length < this.page * this.perPage) {
          this.forbitUploadProjects = true;
          return;
        }
        this.page += 1;
      } else if (window.innerWidth > 768
          && scrollTop + clientHeight >= scrollHeight - scrollBuffer
          && !this.isForbitUploadProjects) {
        // this.getMarketplaceProjects(
        //   this.activeMarketplaceIndex === -100 ? -100
        //     : this.marketplaces[this.activeMarketplaceIndex]?.id,
        // );
        if (this.marketplaceProjects.length < this.page * this.perPage) {
          this.forbitUploadProjects = true;
          return;
        }
        this.page += 1;
      }
    },
  },
  async mounted() {
    this.activeMarketplaceIndex = localStorage.getItem('marketplaceIndex') || 0;
    window.addEventListener('scroll', this.handleScroll);
    if (!this.$route.params.type) {
      this.$router.replace({ path: '/profile/matchmaking/buyer' });
    }
    this.isBuyer = this.$route.params.type === 'buyer';
    this.$store.commit('SET_IS_BUYER', this.isBuyer);
    this.$store.subscribe(async (mutation) => {
      if (mutation.type === 'SET_IS_BUYER') {
        this.isBuyer = mutation.payload;
        this.page = 1;
        this.marketplaceProjects = [];
        this.isForbitUploadProjects = false;
        await this.getMarketplaceProjects(
          this.activeMarketplaceIndex === -100 ? -100
            : this.marketplaces[this.activeMarketplaceIndex]?.id,
        );
      }
    });
    await this.getMarketplaces();
    await this.getMarketplaceProjects(
      this.activeMarketplaceIndex === -100 ? -100
        : this.marketplaces[this.activeMarketplaceIndex]?.id,
    );
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>
