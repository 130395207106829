<template>
  <div class="market-project">
    <div class="d-flex justify-content-center p-2"
      v-if="loadingProjects && !marketplaceProjects.length">
      <div class="loader"></div>
    </div>
    <div v-else>
      <BModal
      data-id="modal-request-offer"
      if="offerFormRequest"
      ref="offerFormRequest"
      class="modal-request-offer"
      size="xl"
      hide-header
      hide-footer>
        <OfferFormRequest
          :marketplaceId="selectedMarketplaceId"
          :formId="formId"
          :isNewOffer="true"
          :projectId="selectedProjectId"
          @onCreatedOffer="onCreatedOffer"
          @onSaveOffer="onSaveOffer"
          @close="closeModal"
        />
      </BModal>
      <div v-if="!marketplaceProjects.length && !isFiltered">
        <div v-if="isBuyer">
          <div class="no-content">
            <p>Here is <strong>the example project</strong>, <br>
              but you can
              <a class="link" href="" @click.stop.prevent='$emit("onRequestOffer")'>
                Request your own
              </a>.
            </p>
          </div>
          <div class="market-project__list">
            <div class="market-project__item offered">
              <div class="market-project__content">
                <h3 class="market-project__title" style="font-size: 16px;">
                  This is just an example project.
                  To get quotes for your project select a market
                  place and click the request offer button.
                   You can choose all the details like country and category there.
                    If you need help go to
                    <a href="https://www.wethink.eu/groups/energy-transition-market"
                    target="_blank">FAQ</a> and write us a message.
                </h3>
                <ul class="market-project__category">
                  <li><span>Country: <strong>Austria</strong></span></li>
                  <li><span>Category: <strong>Wind solar PV EPC</strong></span></li>
                </ul>
              </div>
              <div class="market-project__actions">
                <div class="market-project__info offer">
                  <strong>4</strong> offers from Sellers
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="no-content" v-if="marketplace">
            <p>There are <strong>no projects</strong>
              in the selected Marketplace category {{ marketplace.name }}. </p>
            <p>But you can go through other projects below.</p>
          </div>
          <div v-else>
            <p>There are <strong>no projects</strong>
              in the selected Marketplace category. </p>
            <p>But you can go through other projects below.</p>
          </div>
        </div>
      </div>
      <div v-else-if="!marketplaceProjects.length && isFiltered">
        <div class="market-project__parameters no-content">
          <p>Sorry, <strong>no projects found matching your search criteria.</strong></p>
          <p>Please try adjusting your search terms for better results. Or you can
            <a class="link" href="" @click.stop.prevent='$emit("onRequestOffer")'>
              Request your own.
            </a>
          </p>
        </div>
      </div>
      <div v-else>
        <div class="market-project__parameters">
          <p v-if="marketplaceProjects.length > 1">
            <!-- eslint-disable -->
            <strong>{{ marketplaceProjects.length }}</strong> projects with such parameters are shown
          </p>
          <p v-else>
            <strong>{{ marketplaceProjects.length }}</strong> project with such parameters is shown
          </p>
          <p v-if="marketplace">
            Marketplace: <strong>{{ marketplace.name }}</strong>
          </p>
          <p v-else>
            Marketplace: <strong>All</strong>
          </p>
          <p v-if="selectedCountries.length">
            Countries: <strong>{{ selectedCountries.join(', ') }}</strong>
          </p>
          <div v-if="marketplace">
            <div v-for="(category, index) in marketplace.filters" :key="index">
              <div v-if="index !== 'countries'">
                <div v-if="category.type === 'dropbox'">
                  <div v-if="category.multiple">
                    <div v-for="(value, idx) in category.initValue" :key="idx">
                      <div v-if="category.values[value]">
                        {{ category.name }}: <strong>{{ category.values[value] }}</strong>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div v-if="dynamycDropboxes[index] && dynamycDropboxes[index].length">
                      {{ category.name }}:
                      <span v-for="(value, idx) in dynamycDropboxes[index]" :key="idx">
                        <span v-if="category.values[value]">
                          <strong>{{ category.values[value] }}</strong>
                          <span v-if="idx !== dynamycDropboxes[index].length - 1">, </span>
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div v-if="dynamycInputs[index]">
                    {{ category.name }}: <strong>{{ dynamycInputs[index] }}</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div v-for="marketplace in marketplaces" :key="marketplace.id">
              <div v-for="(category, index) in marketplace.filters" :key="index">
                <div v-if="index !== 'countries'">
                  <div v-if="category.type === 'dropbox'">
                    <div v-if="category.multiple">
                      <div v-for="(value, idx) in category.initValue" :key="idx">
                        <div v-if="category.values[value]">
                          {{ category.name }}: <strong>{{ category.values[value] }}</strong>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <div v-if="dynamycDropboxes[index] && dynamycDropboxes[index].length">
                        {{ category.name }}:
                        <span v-for="(value, idx) in dynamycDropboxes[index]" :key="idx">
                          <span v-if="category.values[value]">
                            <strong>{{ category.values[value] }}</strong>
                            <span v-if="idx !== dynamycDropboxes[index].length - 1">, </span>
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div v-if="dynamycInputs[index]">
                      {{ category.name }}: <strong>{{ dynamycInputs[index] }}</strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="market-project__list"
          v-for="(item, index) in paginatedMarketplaceProjects" :key="index">
          <!-- eslint-disable-next-line max-len -->
          <div class="market-project__item" :class="{approved: item.status === 2, expired: item.status === 4}">
            <span v-if="isBuyer && unreadMessagesCount(item.id)"
              class="message_badge">
              {{ unreadMessagesCount(item.id) }}
            </span>
            <div class="market-project__content">
              <!-- eslint-disable-next-line max-len -->
              <h3 class="market-project__title pointer d-flex justify-content-between align-items-center"
                @click="$router.push(`/profile/matchmaking${isBuyer ? '/buyer' : '/seller'}/project/${item.company_country.iso}-${item.id}`)">
                {{ item.name }}
                <strong
                  v-if="!marketplace"
                  :id="'marketplace-name'+index"
                  style="font-size: 12px;">
                  {{ item.marketplace.name }}
                  <BTooltip
                    v-if="!marketplace"
                    :target="'marketplace-name'+index"
                    triggers="hover"
                    placement="top"
                    title="Marketplace"
                  />
                </strong>
              </h3>
              <ul class="market-project__category">
                <li :id="`${item.name}-${item.company_country.name}`" style="cursor: default;">
                  <span>Country: <strong>{{ item.company_country.name }}</strong></span>
                </li>
                <div v-for="(category, _index) in item.data" :key="_index" class="d-flex">
                  <div v-if="category" class="d-flex">
                    <div v-if="category.type === 'dropbox'" class="d-flex">
                      <div v-if="!category.multiple && category.initValue" class="d-flex">
                        <li v-if="category.values[category.initValue]"
                          :id="`${item.name}-${category.name}`" style="cursor: default;">
                          <span>{{ category.name }}:
                            <strong>{{ category.values[category.initValue] }}</strong>
                          </span>
                        </li>
                      </div>
                      <div v-else class="d-flex">
                        <li>{{ category.name }}:
                          <span v-for="(value, idx) in category.initValue" :key="idx" >
                            <strong>{{ category.values[value] }}</strong>
                            <span v-if="idx !== category.initValue.length - 1">, </span>
                          </span>
                        </li>
                      </div>
                    </div>
                    <div v-else>
                      <li v-if="category.initValue" :id="`${item.name}-${category.name}`"
                        style="cursor: default;">
                        <span>{{ category.name }}:
                          <strong >{{ category.initValue }}</strong>
                        </span>
                      </li>
                    </div>
                  </div>
                </div>
              </ul>
            </div>
            <div v-if="isBuyer">
              <div v-if="item.status === 2">
                <!-- item.status: {{ item.status }} -->
                <div class="market-project__actions">
                  <div class="market-project__info" :class="{offer: item.status === 2}">
                    <span class="deadline-info">
                      <i class="icon-calendar"></i>Offer deadline:
                      <strong>{{ formattedDeadline(item.deadline_at) }}</strong>
                    </span>
                    Seller/offer is chosen
                  </div>
                  <div class="market-project__buttons">
                    <!-- eslint-disable-next-line max-len -->
                    <a @click.stop="$router.push(`/profile/matchmaking${isBuyer ? '/buyer' : '/seller'}/project/${item.company_country.iso}-${item.id}`)"
                    class="btn-action">
                      See approved offer
                    </a>
                  </div>
                </div>
              </div>
              <div v-else-if="item.status === 4">
                <div class="market-project__actions">
                  <div class="market-project__info">
                    <strong style="color: black;">
                    This project is expired without approval of any offer!
                    </strong>
                  </div>
                  <div class="market-project__buttons">
                    <!-- eslint-disable-next-line max-len -->
                    <a @click.stop="$router.push(`/profile/matchmaking${isBuyer ? '/buyer' : '/seller'}/project/${item.company_country.iso}-${item.id}`)"
                      class="btn-action">
                      Extend deadline?
                    </a>
                    <!-- eslint-disable-next-line max-len -->
                    <a v-if="item.offers_count > 0" @click.stop="$router.push(`/profile/matchmaking${isBuyer ? '/buyer' : '/seller'}/project/${item.company_country.iso}-${item.id}`)"
                      class="btn-action">
                      Keep talking to sellers
                    </a>
                  </div>
                </div>
              </div>
              <div v-else>
                <div v-if="item.offers_count === 1"
                  class="market-project__actions">
                  <div class="market-project__info offer">
                    <span class="deadline-info">
                      <i class="icon-calendar"></i>Offer deadline:
                      <strong>{{ formattedDeadline(item.deadline_at) }}</strong>
                    </span>
                    <strong>{{ item.offers_count }}</strong> offer from Seller
                  </div>
                  <div class="market-project__buttons">
                    <!-- eslint-disable-next-line max-len -->
                    <a @click.stop="$router.push(`/profile/matchmaking${isBuyer ? '/buyer' : '/seller'}/project/${item.company_country.iso}-${item.id}`)"
                    class="btn-action">
                      See Offer
                    </a>
                  </div>
                </div>
                <div v-else-if="item.offers_count > 1"
                  class="market-project__actions">
                  <div class="market-project__info offer">
                    <strong>{{ item.offers_count }}</strong> offers from Sellers
                  </div>
                  <div class="market-project__buttons">
                    <!-- eslint-disable-next-line max-len -->
                    <a @click.stop="$router.push(`/profile/matchmaking${isBuyer ? '/buyer' : '/seller'}/project/${item.company_country.iso}-${item.id}`)"
                    class="btn-action">
                      See Offers
                    </a>
                  </div>
                </div>
                <div v-else class="market-project__actions">
                  <div class="market-project__info">
                    No offers yet
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="market-project__actions">
                <div class="market-project__info offer">
                  <span class="deadline-info">
                    <i class="icon-calendar"></i>Offer deadline:
                    <strong>{{ formattedDeadline(item.deadline_at) }}</strong>
                  </span>
                  <span v-if="item.saved" class="status saved">
                    Saved
                  </span>
                  <span v-if="item.offers_count === 1">
                    <strong>{{ item.offers_count }}</strong> offer from Seller
                  </span>
                  <span v-else-if="item.offers_count > 1">
                    <strong>{{ item.offers_count }}</strong> offers from Sellers
                  </span>
                  <span v-else>
                    No offers yet
                  </span>
                </div>
                <div class="market-project__buttons">
                  <a v-if="!item.saved" @click="sellerSaveProject(item.id)"
                    class="btn-action">Save</a>
                  <a v-else @click="sellerUnsaveProject(item.id)"
                    class="btn-action">Unsave</a>
                  <a v-if="item.offers_allow === 1 && item.status !== 2"
                    class="btn-action"
                    @click="makeAnOffer(item)"
                    >Make an Offer
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center p-2"
        v-if="loadingProjects">
        <div class="loader"></div>
      </div>
    </div>
  </div>
</template>

<script>
import MatchmakingService from '@/services/MatchmakingService';
import { BModal, BTooltip } from 'bootstrap-vue';
import exception from '@/mixins/exception';
import OfferFormRequest from '@/components/matchmaking/OfferFormRequest.vue';
import moment from 'moment';

export default {
  name: 'MatchmakingProjectsList',
  mixins: [exception],
  data() {
    return {
      formId: '',
      selectedProjectId: 0,
      selectedMarketplaceId: 0,
    };
  },
  components: {
    BModal,
    BTooltip,
    OfferFormRequest,
  },
  props: {
    marketplaceProjects: {
      type: Array,
      required: true,
    },
    isFiltered: {
      type: Boolean,
      required: true,
    },
    loadingProjects: {
      type: Boolean,
      required: true,
    },
    marketplace: {
      type: Object || null,
    },
    marketplaces: {
      type: Array,
      required: true,
    },
    selectedCountries: {
      type: Array,
      required: true,
    },
    dynamycDropboxes: {
      type: Object,
      required: true,
    },
    dynamycInputs: {
      type: Object,
      required: true,
    },
    searchInput: {
      type: String,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
  },
  methods: {
    compareDate(deadline) {
      const deadlineDate = moment(deadline, 'YYYY.MM.DD');
      const currentDate = moment();
      return deadlineDate.startOf('day').isBefore(currentDate.startOf('day'));
    },
    onCreatedOffer(offerData) {
      const indexOfActive = offerData.status.findIndex((item) => item.active === true);
      if (indexOfActive !== -1) {
        const type = String(offerData.status[indexOfActive].name).toLowerCase();
        this.$router.push(`/profile/matchmaking/seller/offers?type=${type}`);
      } else {
        this.$router.push('/profile/matchmaking/seller/offers');
      }
    },
    onSaveOffer() {
      this.closeModal();
    },
    generateFormId() {
      this.formId = Math.random().toString(36).substring(2, 15)
        + Math.random().toString(36).substring(2, 15);
    },
    closeModal() {
      this.selectedProjectId = 0;
      this.selectedMarketplaceId = 0;
      this.$refs.offerFormRequest.hide();
    },
    makeAnOffer(project) {
      this.selectedProjectId = project.id;
      this.selectedMarketplaceId = project.marketplace.id;
      this.generateFormId();
      this.$refs.offerFormRequest.show();
    },
    showProject(project) {
      this.$emit('showProject', project);
    },
    async sellerSaveProject(projectId) {
      try {
        await MatchmakingService.sellerSaveProject({ project_id: projectId });
        this.$emit('toggleSavedProject', projectId);
      } catch (error) {
        this.handleException(error);
      }
    },
    async sellerUnsaveProject(projectId) {
      try {
        await MatchmakingService.sellerUnsaveProject({ project_id: projectId });
        this.$emit('toggleSavedProject', projectId);
      } catch (error) {
        this.handleException(error);
      }
    },
    unreadMessagesCount(projectId) {
      return this.$store.getters.getUnreadMarketplaceData
      && this.$store.getters.getUnreadMarketplaceData.buyer
      && this.$store.getters.getUnreadMarketplaceData.buyer.projects
      && this.$store.getters.getUnreadMarketplaceData.buyer.projects[projectId];
    },
  },
  computed: {
    isBuyer() {
      return this.$route.params.type === 'buyer';
    },
    formattedDeadline() {
      return (deadline) => {
        if (deadline === '∞') {
          return 'not specified';
        }
        return deadline;
      };
    },
    paginatedMarketplaceProjects() {
      return this.marketplaceProjects.slice(0, this.page * this.perPage);
    },
  },
};
</script>

<style scoped lang="scss">
.link {
  text-decoration: underline;
  font-weight: 700;
}
.market-project__item {
  position: relative;
}
.message_badge {
  &.two {
    width: 30px;
    border-radius: 40%;
  }

  &.three {
    width: 40px;
    border-radius: 40%;
  }

  display: flex;
  align-items: center;
  justify-content: center;

  width: 20px;
  height: 20px;

  position: absolute;
  top: 5px;
  left: 5px;

  background-color: red;
  border-radius: 50%;

  z-index: 1;

  color: #fff;
  font-size: 12px;
  font-weight: 600;

  @media (max-width: 992px) {
    left: 10px;
  }
}
</style>
