<template>
  <div class="accFeed__wrapper matchmaking__wrapper">
    <div class="accFeed__sidebar matchmaking__sidebar">
      <div class="accFeed__box matchmaking__box">
        <div v-if="isBuyer">
          <span v-if="isShowProjects"
            class="btn btn-primary w-100"
            @click="onRequestOffer()">Request Offer
          </span>
          <span v-else
            class="btn btn-primary w-100"
            @click="backToProjects()">Back to Projects
          </span>
        </div>
        <div v-else>
          <span v-if="isShowProjects"
            class="btn btn-primary w-100"
            @click="$router.push('/profile/matchmaking/seller/offers')"
            >My Offers & Projects
          </span>
          <span v-else
            class="btn btn-primary w-100"
            @click="$router.push('/profile/matchmaking/seller')">
            Project search
          </span>
        </div>
      </div>
      <BModal
        ref="errorModal"
        id="errorModal"
        class="modal-request-offer"
        size="xs"
        centered
        hide-header
        hide-footer>
        <div class="body">
          <div class="text-center">
            <p>To request an offer you need to select a Marketplace first</p>
            <button class="btn btn-primary" @click="closeErrorModal()">Close</button>
          </div>
        </div>
      </BModal>

      <BModal
        ref="modalRequestOffer"
        id="modalRequestOffer"
        class="modal-request-offer"
        size="lg"
        hide-header
        hide-footer>
        <div class="body">
          <FormRequest v-if="this.marketplaces &&
            this.marketplaces[this.activeMarketplaceIndex] &&
            this.marketplaces[this.activeMarketplaceIndex].fields &&
            this.marketplaces[this.activeMarketplaceIndex].filters"
            :marketplaceId="this.marketplaces[this.activeMarketplaceIndex].id"
            :marketplaceName="this.marketplaces[this.activeMarketplaceIndex].name"
            :fields="fields"
            :countries="this.marketplaces[this.activeMarketplaceIndex].filters.countries"
            :formId="formId"
            :isNewProject="true"
            @onSaveProject="onSaveProject"
            @close="closeModal()"/>
        </div>
      </BModal>
      <div class="search__form-container">
        <h3>Search</h3>
        <div class="search__form">
          <input type="text" v-model="searchInput">
          <button class="search__form-btn">
            <span class="search__form-icon"></span>
          </button>
        </div>
      </div>
      <div class="accordion" id="accordion">
        <div class="card">
          <div class="card-header" id="headingOne">
            <button class="btn-accordion collapsed" type="button"
              data-target="#collapseOne" aria-expanded="true">
              <span>Marketplace</span>
            </button>
          </div>
          <div id="collapseOne" class="show" aria-labelledby="headingOne">
            <div class="card-body">
              <ul class="channel__list">
                <li class="channel__item" v-if="isBuyer">
                  <span class="channel__link"
                  :class="{active: +activeMarketplaceIndex === -100}"
                  @click="selectAllMarketplace"
                  style="cursor: pointer">
                    All my projects
                  </span>
                </li>
                <li class="channel__item"
                  v-for="(marketplace, index) in marketplaces"
                  :key="marketplace.id"
                  :class="{collapseditem: index > 9}">
                  <span class="channel__link"
                    :class="{active: +activeMarketplaceIndex === index}"
                    :id="marketplace.name + marketplace.id"
                    @click="selectMarketplace(marketplace)"
                    style="cursor: pointer">
                    {{ marketplace.name }}
                    <span v-if="+marketplace.id === 0 && marketplaces.length > 10">
                      ({{ marketplaces.length-1 }})
                    </span>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div v-if="!isBuyer">
          <div v-if="marketplaces[activeMarketplaceIndex] && countries.length"
            class="card">
            <div class="card-header" id="headingTwo">
              <button class="btn-accordion collapsed" type="button" data-toggle="collapse"
              data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                <span>Country</span>
              </button>
            </div>
            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo">
              <div class="card-body">
                <div class="checkbox-group__wrapper">
                  <BFormCheckboxGroup
                    v-model="selectedCountries"
                    :options="countries"
                    class="mb-3"
                    value-field="name"
                    text-field="name"
                    disabled-field="notEnabled"
                  />
                </div>
              </div>
            </div>
          </div>
          <div v-if="+activeMarketplaceIndex !== -100">
            <div v-if="marketplaces[activeMarketplaceIndex] &&
              marketplaces[activeMarketplaceIndex].filters">
              <div v-for="(category, index) in marketplaces[activeMarketplaceIndex].filters"
                :key=index>
                <div v-if="index !== 'countries'">
                 <div class="card">
                  <div class="card-header" :id="'heading' + index">
                    <button class="btn-accordion collapsed" type="button" data-toggle="collapse"
                    :data-target="'#collapse' + index" aria-expanded="false"
                      :aria-controls="'collapse' + index">
                      <span>{{ category.name }}</span>
                      <i v-if="category.notice !== ''">
                        <i class="icon-about" :id="`${category.name}-filters`"></i>
                        <BTooltip
                          custom-class="tooltip-info"
                          :target="`${category.name}-filters`"
                          :title="category.notice"
                          triggers="hover"
                          placement="top"
                        />
                      </i>
                    </button>
                  </div>
                  <div :id="'collapse' + index" class="collapse"
                  :aria-labelledby="'heading' + index" style="">
                    <div class="card-body">
                      <div v-if="category.type === 'dropbox'">
                        <BFormCheckboxGroup
                          v-model="dynamycDropboxes[index]"
                          :options="category.values.map((value, idx) => ({ id: idx, name: value }))"
                          class="mb-3"
                          value-field="id"
                          text-field="name"
                          disabled-field="notEnabled"
                        />
                      </div>
                      <div v-else class="search__form">
                          <input type="text" v-model="dynamycInputs[index]">
                      </div>
                    </div>
                  </div>
                 </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div v-if="countries" class="card">
              <div class="card-header" id="headingTwo">
                <button class="btn-accordion collapsed" type="button" data-toggle="collapse"
                data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  <span>Country</span>
                </button>
              </div>
              <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo">
                <div class="card-body">
                  <div class="checkbox-group__wrapper">
                    <BFormCheckboxGroup
                      v-model="selectedCountries"
                      :options="countries"
                      class="mb-3"
                      value-field="name"
                      text-field="name"
                      disabled-field="notEnabled"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div v-for="marketplace in marketplaces" :key="marketplace.id">
              <div v-if="marketplace.filters">
                <div v-for="(category, index) in marketplace.filters" :key=index>
                  <div v-if="index !== 'countries'">
                    <div class="card">
                      <div class="card-header" :id="'heading' + index">
                        <button class="btn-accordion collapsed" type="button" data-toggle="collapse"
                        :data-target="'#collapse' + index" aria-expanded="false"
                          :aria-controls="'collapse' + index">
                          <span>{{ category.name }}</span>
                          <i v-if="category.notice !== ''">
                            <i class="icon-about tooltip-info" :id="`${category.name}-filters`"></i>
                            <BTooltip
                              custom-class="tooltip-info"
                              :target="`${category.name}-filters`"
                              :title="category.notice"
                              triggers="hover"
                              placement="top"
                            />
                          </i>
                        </button>
                      </div>
                      <div :id="'collapse' + index" class="collapse"
                      :aria-labelledby="'heading' + index" style="">
                        <div class="card-body">
                          <div v-if="category.type === 'dropbox'">
                            <BFormCheckboxGroup
                              v-model="dynamycDropboxes[index]"
                              :options="category.values.map((value, idx) => (
                                { id: idx, name: value }))"
                              class="mb-3"
                              value-field="id"
                              text-field="name"
                              disabled-field="notEnabled"
                            />
                          </div>
                          <div v-else class="search__form">
                              <input type="text" v-model="dynamycInputs[index]">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="sidebar-block">
        <a class="link" href="https://www.wethink.eu/groups/energy-transition-market" target="_blank">FAQ</a>
      </div>
    </div>
    <div>
      <ProjectsList
        v-if="isShowProjects &&
        marketplaces[activeMarketplaceIndex] &&
        marketplaces[activeMarketplaceIndex].name ||
        isAllMarketplaces"
        :marketplaceProjects="filteredMarketplaceProjects"
        :isFiltered="isFiltered"
        :loadingProjects="isLoadingProjects"
        :marketplaces="marketplaces"
        :marketplace="isAllMarketplaces ? null : marketplaces[activeMarketplaceIndex]"
        :selectedCountries="selectedCountries"
        :dynamycDropboxes="dynamycDropboxes"
        :dynamycInputs="dynamycInputs"
        :searchInput="searchInput"
        :page="page"
        :perPage="perPage"
        @showProject="showProject"
        @onRequestOffer="onRequestOffer"
        @toggleSavedProject="toggleSavedProject"
      />
    </div>
  </div>
</template>
<script>
import exception from '@/mixins/exception';
import FormRequest from '@/components/matchmaking/ProjectFormRequest.vue';
import MatchmakingService from '@/services/MatchmakingService';
import { BTooltip, BFormCheckboxGroup, BModal } from 'bootstrap-vue';
import ProjectsList from '@/components/matchmaking/ProjectsList.vue';
import websocket from '@/mixins/websocket';
import me from '@/mixins/me';

export default {
  name: 'FilterMatchmaking',
  mixins: [exception, websocket, me],
  components: {
    BTooltip, BFormCheckboxGroup, FormRequest, BModal, ProjectsList,
  },
  data() {
    return {
      activeMarketplaceIndex: 0,
      modalRequestOffer: 'modalRequestOffer',
      selectedCountries: [],
      dynamycDropboxes: {},
      dynamycInputs: {},
      formId: '',
      isLoadingProjects: true,
      isShowProjects: true,
      selectedProject: null,
      searchInput: '',
    };
  },
  props: {
    marketplaces: {
      type: Array,
      required: true,
    },
    marketplaceProjects: {
      type: Array,
      required: true,
    },
    marketplaceProjectOffers: {
      type: Object,
      required: true,
    },
    loadingProjects: {
      type: Boolean,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
  },
  methods: {
    async getMarketplaces() {
      try {
        this.$store.commit('SET_IS_LOADING', true);
        const { data } = await MatchmakingService.marketplaces();
        this.marketplaces = data;
      } catch (error) {
        this.handleException(error);
      } finally {
        this.$store.commit('SET_IS_LOADING', false);
      }
    },
    async getAllMarketplaceProjects() {
      try {
        this.$store.commit('SET_IS_LOADING', true);
        const { data } = await MatchmakingService.marketplaceProjects();
        this.marketplaceProjects = data;
      } catch (error) {
        this.handleException(error);
      } finally {
        this.$store.commit('SET_IS_LOADING', false);
      }
    },
    generateFormId() {
      this.formId = Math.random().toString(36).substring(2, 15)
        + Math.random().toString(36).substring(2, 15);
    },
    onSaveProject(marketplaceId, isCreatedNewProject = false) {
      this.emitMarketplaceProjects(this.marketplaces
        .find((marketplace) => marketplace.id === marketplaceId), isCreatedNewProject);
      setTimeout(() => {
        this.closeModal();
      }, 1000);
    },
    closeModal() {
      this.$refs.modalRequestOffer.hide();
    },
    closeErrorModal() {
      this.$refs.errorModal.hide();
    },
    openErrorModal() {
      this.$refs.errorModal.show();
    },
    onRequestOffer() {
      if (this.activeMarketplaceIndex === '-100' || this.activeMarketplaceIndex === -100) {
        this.openErrorModal();
        return;
      }
      this.generateFormId();
      this.$refs.modalRequestOffer.show();
    },
    selectAllMarketplace() { // hardcode id -100 for all marketplaces
      this.activeMarketplaceIndex = -100;
      localStorage.setItem('marketplaceIndex', -100);
      this.emitChangeMarketplace(-100);
    },
    selectMarketplace(marketplace) {
      this.activeMarketplaceIndex = this.marketplaces.indexOf(marketplace);
      localStorage.setItem('marketplaceIndex', this.activeMarketplaceIndex);
      this.emitChangeMarketplace(marketplace.id);
    },
    emitChangeMarketplace(marketplaceId) {
      this.$emit('changeMarketplace', marketplaceId);
    },
    emitMarketplaceProjects(marketplace, isCreatedNewProject = false) {
      if (isCreatedNewProject) {
        this.$emit('getMarketplaceProjects', marketplace, true);
        return;
      }
      this.$emit('getMarketplaceProjects', marketplace);
    },
    async showProject(projectId) {
      window.scrollTo(0, 0);
      console.log(projectId, ' projectId');
      try {
        this.isLoadingProjects = true;
        const { data } = await MatchmakingService.getMarketplaceProject(projectId);
        this.selectedProject = data;
        this.$emit('isForbitUploadProjects', true);
        console.log(data, ' res');
      } catch (error) {
        this.handleException(error);
      } finally {
        this.isLoadingProjects = false;
      }
      this.isShowProjects = false;
    },
    backToProjects() {
      this.selectedProject = null;
      this.isShowProjects = true;
      this.$emit('isForbitUploadProjects', false);
    },
    toggleSavedProject(projectId) {
      this.$emit('toggleSavedProject', projectId);
    },
    clearAllFilters() {
      this.selectedCountries = [];
      this.dynamycDropboxes = {};
      this.dynamycInputs = {};
      this.searchInput = '';
    },
    changeMode() {
      this.clearAllFilters();
      setTimeout(() => {
        if (!this.isBuyer && this.isAllMarketplaces) {
          localStorage.setItem('marketplaceIndex', 0);
          this.activeMarketplaceIndex = 0;
        }
      }, 0);
    },
    // setMessagesCountFromWebsocket(event) {
    //   const data = JSON.parse(event.data);
    //   // console.log(data, ' DATA');
    // },
  },
  computed: {
    isAllMarketplaces() {
      return +this.activeMarketplaceIndex === -100;
    },
    authUser() {
      return this.$store.getters.getAuthUser;
    },
    isFiltered() {
      return Object.keys(this.dynamycDropboxes)
        .some((key) => this.dynamycDropboxes[key].length > 0) || this.selectedCountries.length > 0
        || Object.keys(this.dynamycInputs).some((key) => this.dynamycInputs[key])
        || this.searchInput.length > 0;
    },
    fields() {
      if (this.marketplaces && this.marketplaces[this.activeMarketplaceIndex]
        && this.marketplaces[this.activeMarketplaceIndex].fields) {
        return Object.entries(this.marketplaces[this.activeMarketplaceIndex].fields);
      }
      return [];
    },
    filteredMarketplaceProjects() {
      if (!this.isFiltered && !this.searchInput.length) {
        return this.marketplaceProjects;
      }
      return this.marketplaceProjects.filter((item) => {
        /* eslint-disable */
        const countriesCondition = !this.selectedCountries.length || this.selectedCountries.includes(item.company_country.name);
        const dropboxesCondition = Object.keys(this.dynamycDropboxes).every((key) => {
          const dropboxItems = this.dynamycDropboxes[key];
          /* eslint-disable */
          const itemValues = item.data.filter((dataItem) => dataItem.id === key).map((dataItem) => +dataItem.initValue) || [];
          if (dropboxItems && dropboxItems.length) {
            return dropboxItems.some((dropbox) => itemValues.includes(dropbox));
          }
          return true;
        });
        const inputsCondition = Object.keys(this.dynamycInputs).every((key) => {
          const inputValue = this.dynamycInputs[key];
          /* eslint-disable */
          const itemValues = item.data.filter((dataItem) => dataItem.id === key).map((dataItem) => dataItem.initValue) || [];
          if (inputValue) {
            /* eslint-disable */
            return itemValues && itemValues.some((value) => value && value.toLowerCase().includes(inputValue.toLowerCase()));
            // return itemValues.some((value) => value.toLowerCase().includes(inputValue.toLowerCase()));
          }
          return true;
        });
        const input = this.searchInput.trim().toLowerCase();
        const searchCondition = !input.length ||
        item.name.toLowerCase().includes(input) ||
        item.company_country.name.toLowerCase().includes(input) ||
        item.data.some((dataItem) => {
          if (dataItem.type === 'dropbox') {
            if (dataItem.multiple && dataItem.initValue) {
              return String(dataItem.values[dataItem.initValue]).toLocaleLowerCase().includes(input);
            } else {
              return dataItem.initValue && dataItem.values[dataItem.initValue] && String(dataItem.values[dataItem.initValue]).toLocaleLowerCase().includes(input);
            }
          } else {
            return dataItem.initValue && dataItem.initValue.toLowerCase().includes(input);
          }
        });

        return countriesCondition && dropboxesCondition && inputsCondition && searchCondition;
      });
    },
    isBuyer() {
      return this.$route.params.type === 'buyer';
    },
    countries() {
      return this.marketplaceProjects && this.marketplaceProjects.reduce((acc, project) => {
        if (project.company_country) {
          if (!acc.some((country) => country.name === project.company_country.name)) {
            acc.push({ name: project.company_country.name, notEnabled: false });
          }
        }
        return acc;
      }, []);
    },
  },
  watch: {
    loadingProjects() {
      this.isLoadingProjects = this.loadingProjects;
    },
  },
  mounted() {
    this.activeMarketplaceIndex = localStorage.getItem('marketplaceIndex') || 0;
    this.$eventBus.$on('setMatchmaking', this.changeMode);
  },
  onUnmounted() {
    this.$eventBus.$off('setMatchmaking', this.changeMode);
  },
};
</script>
<style lang="scss" scoped>
.modal-request-offer {
  position: absolute;
}

.spinner {
  width: 20px;
  height: 20px;
  display: inline-block;
  background-size: contain;
  background-color: #004360;
  margin-right: 10px;
  transform: rotateZ(-54deg);
  animation: 4s linear 0s infinite alternate move_eye;
}

@keyframes move_eye {
  from { transform: rotateZ(0deg); }
  to { transform: rotateZ(360deg); }
}
</style>
